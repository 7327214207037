.pageContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
}

.container {
  margin: auto;
  display: grid;
  max-width: 300px;
  text-align: center;
}
.container h2 {
  font-size: 22px;
}

.containerInner {
  display: grid;
  grid-template-columns: 120px 120px;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
}

.box {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 60px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.1s;

  box-shadow: 0px 0px 5px 1px rgba(138, 138, 174, 0.76);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(138, 138, 174, 0.76);
  -moz-box-shadow: 0px 0px 5px 1px rgba(138, 138, 174, 0.76);
}
.box:hover {
  cursor: pointer;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.boxInfo {
  position: absolute;
  right: 5px;
  top: 5px;
  color: gray;
}
