* {
  box-sizing: border-box;
}

*:disabled {
  cursor: not-allowed !important;
  pointer-events: inherit !important;
}

.router__link {
  text-decoration: none;
  color: inherit;
}

.paper__padding {
  padding: 20px;
}

.form__control_margin {
  margin-bottom: 15px !important;
}

.form__control__row {
  display: grid !important;
}

input:disabled {
  cursor: not-allowed;
}

main {
  max-width: calc(100% - 240px);
}

@media screen and (max-width: 959px) {
  main {
    max-width: 100% !important;
  }
}
