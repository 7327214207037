/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the IbanElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=iban#elements_create-options-classes
*/

#checkout__form input,
#checkout__form select,
.StripeElement {
  /* height: 40px; */
  padding: 10px 12px;

  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

#checkout__form input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

#checkout__form .StripeElement--invalid {
  border-color: #fa755a;
}

#checkout__form .StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* #formEl {
  width: 100%;
  display: block;
} */

/* #checkout__form .col {
  width: 50%;
} */

.checkout__container {
  background: #fafafa;
  padding-top: 20px;
}
#checkout__form {
  width: 500px;
  max-width: 90%;
  margin: auto;
  background: white;
  padding: 20px;
  box-shadow: 0 2px 5px 1px rgb(146, 146, 146);
  border-radius: 4px;
}

#checkout__form .form-row {
  display: flex;
  width: 100%;
  column-gap: 10px;
  margin-bottom: 15px;
}

#checkout__form label,
#checkout__form .checkoutInput {
  width: 100%;
}

.radio__labelinput__container {
  margin: 0 10px;
}
.radio__labelinput__container input,
.radio__labelinput__container label {
  cursor: pointer;
}
.regularity__radio__container {
  margin: 50px 0;
}
.mandate-acceptance p {
  font-size: 13px;
  font-weight: 500;
  color: #616161;
}

/* == Popup WELL PLATE == */
.plate__container {
  display: grid;
  grid-template-columns: 150px 150px 150px;
  gap: 1em;
  justify-content: center;
}

.plate__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  cursor: zoom-in;
  transition: 0.5s all ease-in-out;
}
.plate__image__container {
  overflow: hidden;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.plate__image__container:hover > img {
  transform: scale(2);
}
/* == END Popup WELL PLATE == */
.plate__visual {
  width: 100%;
  /* height: 150px; */
  background-color: #f85a00;
  color: white;
  font-size: 12px;
  margin-bottom: 15px;
  padding: 10px 0 10px 0;
}
.plate__well__name {
  /* text-decoration: underline 3px; */
  border-bottom: solid 3px;
  font-weight: bold;
  font-size: 16px;
  min-width: 280px;
  max-width: 315px;
}
.plate__well__name span {
  text-transform: uppercase;
}
.plate__visual fieldset {
  width: max-content;
  border-radius: 4px;
  border-color: white;
}
.plate__well__important {
  display: inline-block;
  background-color: black;
  font-weight: bold;
  padding: 10px 20px;
}
.plate__well__important__note {
  display: grid;
  /* grid-template-columns: 65px auto 65px; */
  grid-template-columns: 65px auto;
}
.plate__well__important__note__text {
  font-size: 10px;
}
.plate__well__important__note__flag img {
  width: 50px;
}
.msg__error {
  display: flex;
  font-size: 13px;
  color: red;
  font-weight: bold;
  align-items: center;
  gap: 1em;
  margin-bottom: 10px;
}
.important__note {
  font-weight: bold;
}
@media screen and (max-width: 500px) {
  .plate__container {
    grid-template-columns: 100px 100px 100px;
  }
  .plate__image__container {
    width: 100px;
    height: 100px;
  }
  #checkout__form {
    padding: 10px;
    font-size: 14px;
  }
  .plate__well__important__note__text {
    font-size: 8px;
  }
}
@media screen and (max-width: 350px) {
  .plate__container {
    grid-template-columns: 75px 75px 75px;
  }
  .plate__image__container {
    width: 75px;
    height: 75px;
  }
}
