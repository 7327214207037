.border__top__gray {
  border: solid 1px #f2f2f2;
}
.border__bottom__gray {
  border-bottom: solid 1px #f2f2f2;
}
.border__left__gray {
  border: solid 1px #f2f2f2;
}
.border__right__gray {
  border: solid 1px #f2f2f2;
}
.align__right {
  text-align: right;
}
